<template>

  <div>
    <div class="legend">
      <div
        v-for="voteKind in ['for', 'against', 'didntParticipate'].filter((voteKind) =>  calculateVoteResults[voteKind].count > 0)"
        :key="voteKind"
        class="legendColumn"
      >
        <div class="innerColumn">
          <span class="text-body-2 text--secondary">
            {{ calculateVoteResults[voteKind].name }}: <strong>{{ calculateVoteResults[voteKind].count }}</strong> stemmen
          </span>
          <ul>
            <li
              v-for="(actor, i) in sortedVoteDataForLegend[voteKind]"
              :key="i"
              :class="voteKind"
            >
              <span
                  class="text-body-2 text--secondary"
              >
                <strong>{{ actor.actorName }} -</strong> {{ isHeadCount ? actor.actorFraction : actor.fractionSize }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <vote-result-doughnut
      class="mt-2"
      :vote-data="voteResults.detailedResults"
      :vote-distribution="calculateVoteResults"
    />
  </div>
</template>


<script>
import { sortVoteResults } from '@/helpers/voteResultsHelper';
import VoteResultDoughnut from './VoteResultDoughnut.vue';

export default {
  name: 'VoteResultsDetailed',

  components: {
    VoteResultDoughnut,
  },

  props: {
    voteResults: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      isHeadCount: null,
    };
  },

  mounted() {
    this.isHeadCount = this.voteResults.voteForm === 'Hoofdelijk';
    if (this.isHeadCount) {
      // Fix the fractionSize which, for a headCount, is still set to the size of the whole fraction
      // even though its just one person per voteResult that votes.
      this.voteResults.detailedResults.forEach((item) => { item.fractionSize = 1; });
    }
  },

  computed: {
    calculateVoteResults() {
      if (!this.voteResults.detailedResults) return null;
      const results = {
        for: {
          name: 'Voor',
          count: 0,
        },
        against: {
          name: 'Tegen',
          count: 0,
        },
        didntParticipate: {
          name: 'Niet deelgenomen',
          count: 0,
        },
      };
      this.voteResults.detailedResults.forEach((item) => {
        if (item.voteKind === 'Voor') {
          results.for.count += Number(item.fractionSize);
        } else if (item.voteKind === 'Tegen') {
          results.against.count += Number(item.fractionSize);
        } else {
          results.didntParticipate.count += Number(item.fractionSize);
        }
      });
      return results;
    },

    sortedVoteDataForLegend() {
      const sortedVoteData = {
        for: this.voteResults.detailedResults
          .filter((vote) => vote.voteKind === 'Voor'),
        against: this.voteResults.detailedResults
          .filter((vote) => vote.voteKind === 'Tegen'),
        didntParticipate: this.voteResults.detailedResults
          .filter((vote) => !['Voor', 'Tegen'].includes(vote.voteKind)),
      };

      Object.entries(sortedVoteData).forEach(([voteType, voteObj]) => {
        sortedVoteData[voteType] = sortVoteResults(voteObj);
      });

      return sortedVoteData;
    },
  },
};
</script>

<style scoped>
.legend {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  justify-content: start;
  align-items: start;
  gap: 1em;

  .legendColumn {
    flex-grow: 1;
    display: flex;
    justify-content: start;

    .innerColumn {
      min-width: 12em;
      margin: auto 0 ;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        vertical-align: -25%;
        margin: 0.1em 0;

        &::before {
          content: '';
          height: 1.2em;
          width: 1.2em;
          background-color: #bbb;
          border-radius: 50%;
          display: inline-block;
          margin-right: 0.5em;
        }

        &.for::before {
          background-color: #37ada2;
        }

        &.against::before {
          background-color: #e64415;
        }
      }
    }
  }
}
</style>
