var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasReferences())?_c('v-expansion-panel',{},[_c('v-expansion-panel-header',[_vm._v("Gerelateerde documenten (bèta)")]),_c('v-expansion-panel-content',[(_vm.items)?_c('v-timeline',{attrs:{"reverse":false,"dense":"","align-top":""}},_vm._l((_vm.items.data),function(item){return _c('v-timeline-item',{key:item.id,attrs:{"color":_vm.codeToColor(
          (item.type
            && (
              item.type.startsWith('EUROPARL-')
              || item.type.startsWith('EUROCOM-')
              || item.type.startsWith('EUROCOU-')
            )
          )
            ? item.institution
            : item.type
        ),"icon":`${(_vm.id && _vm.id === item.id) ? 'mdi-disc' : ''}`,"small":(_vm.id && _vm.id !== item.id)}},[_c('v-card',{staticClass:"elevation-2",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goToUrlConditionally(item.type, item.id)}}},[_c('v-card-title',{staticClass:"text-subtitle-2 mb-n2"},[_vm._v(" "+_vm._s(_vm.formattedDate(item.publicationDate))+" ")]),_c('v-card-text',{staticClass:"mt-n2"},[_vm._v(" "+_vm._s(_vm.itemStripHtml(item.title))+" "),(item.fragDocu)?_c('span',[_vm._v(" "+_vm._s(_vm.itemFragDocufy(item.fragDocu)))]):_vm._e()]),_c('v-card-text',{staticClass:"text-caption mt-n5"},[_vm._v(" "+_vm._s(_vm.getCleanedRef(item))+" "),(_vm.getProcedureNumber(item))?_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("en")]),_vm._v(" "+_vm._s(_vm.getProcedureNumber(item))+" ")]):_vm._e(),_vm._v(" - "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.codeToName(item.institution))+" "),(item.url)?_c('span',[_vm._v(" "+_vm._s(_vm.itemUrlToSourceName(item.url))+" ")]):_vm._e()])])],1)],1)}),1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }