<template>
  <div class="document">
    <base-layout
      :bookmark-button="true"
      :filter-button="false"
      :back-button="$vuetify.breakpoint.mdAndDown"
      :show-drawer-right="false"
    >
      <template v-slot:drawer-left>
        <document-pdf-bookmarks
          v-if="showPDF && pdfLoaded"
          :pdfBookmarks="pdfBookmarks"
          :pdfBookmarksLoading="pdfBookmarksLoading"
          :keywords="document.keywords || []"
        />
        <document-bookmarks
          v-if="!showPDF"
          :bookmarks="bookmarks"
          :loading="bookmarksLoading"
        />
      </template>

      <template v-slot:identity>
        <a :href="`${kebabDashboard}/dashboard`" class="d-flex align-start">
          <img
            v-if="!$vuetify.theme.dark"
            style="cursor: pointer"
            src="@/assets/polpo-logo-light.png"
            alt="Polpo - Politieke monitoring"
            height="44"
          />
          <img
            v-else
            style="cursor: pointer"
            src="@/assets/polpo-logo-dark.png"
            alt="Polpo - Politieke monitoring"
            height="44"
          />
        </a>
      </template>

      <template v-slot:main-content>
        <div>
          <v-container fluid>
            <v-row dense>
              <!-- left -->
              <v-col
                cols="12"
                :md="rightColumnOpen ? '8' : '12'"
                :xl="rightColumnOpen ? '8' : '12'"
                class="pr-2"
                v-if="document"
              >
                <document-layout
                  :document="document"
                  :link="link"
                  :rssDocument="rssDocument"
                  :pdf="pdf"
                  :showPDF="showPDF && pdfLoaded"
                  :fileFetchDone="fileFetchDone"
                  @toggleShowPDF="toggleShowPDF"
                  @pdfRender="pdfRender"
                  @pdfRendered="pdfRendered"
                  @pdfPageReady="pdfPageReady"
                  :pdfMissing="pdfMissing"
                  :pdfExpandedView="!rightColumnOpen"
                  @togglePdfExpandedView="rightColumnOpen = !rightColumnOpen"
                  :showAudioText="docHasAudio"
                />
              </v-col>

              <!-- right -->
              <v-col
                v-if="document && rightColumnOpen"
                cols="12"
                md="4"
                class="d-none d-md-block"
              >
                <!-- NOTE: Sidebar depends on documentType not on dashboard-->
                <europarl-detail-sidebar
                  v-if="europarlDocument"
                  :document="document"
                  :documentId="documentId"
                ></europarl-detail-sidebar>
                <dlrc-detail-sidebar
                  v-else-if="dlrcDocument"
                  :document="document"
                  :documentId="documentId"
                ></dlrc-detail-sidebar>
                <detail-sidebar
                  v-else-if="otherTypeDocument"
                  :document="document"
                  :documentId="documentId"
                  :docHasAudio="docHasAudio"
                ></detail-sidebar>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </base-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sleep } from '@/helpers/toolbelt';
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import DetailSidebar from '@/modules/documentDetail/components/DetailSidebar.vue';
import EuroparlDetailSidebar from '@/modules/documentDetail/components/EuroparlDetailSidebar.vue';
import DlrcDetailSidebar from '@/modules/documentDetail/components/DlrcDetailSidebar.vue';

import Config from '@/config';
import EventBus from '@/event-bus';

import DocumentBookmarks from '../components/DocumentBookmarks.vue';
import DocumentPdfBookmarks from '../components/DocumentPDFBookmarks.vue';
import DocumentLayout from '../components/DocumentLayout.vue';

export default {
  name: 'document-detail',

  components: {
    BaseLayout,
    DocumentBookmarks,
    DocumentPdfBookmarks,
    DocumentLayout,
    DetailSidebar,
    EuroparlDetailSidebar,
    DlrcDetailSidebar,
  },

  created() {
    // Only continue if polyfill is actually needed
    if (!('scrollBehavior' in document.documentElement.style)) {
      // We are dealing with a very old browser
      this.polyfillNeeded = true;
      // Wait until the Polyfill has been loaded
      import('smoothscroll-polyfill')
        .then((smoothscroll) => {
          smoothscroll.polyfill();
        });
    }
  },

  async mounted() {
    this.$gtag.pageview({
      page_path: `/document/${this.documentId}`,
    });

    await this.$store.dispatch('getDocumentFilterTypesForUser');
    await this.$store.dispatch('clearAllFileData');
    await this.$store.dispatch('setSessionTokenAsCookie');

    this.$nextTick(() => {
      if (this.documentId) {
        this.$store
          .dispatch(`${this.dashboard}GetDocumentById`, {
            id: this.documentId,
            highlightWord: this.highlightWord,
          });
      } else {
        this.$router.push({ name: 'nlDashboard' }).catch(() => {});
      }
    });
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const that = vm;
      if (from.fullPath === '/') {
        that.$store.dispatch('setReferrer', false);
      }
    });
  },

  props: ['dashboard'],

  data() {
    return {
      rightColumnOpen: true,
      polyfillNeeded: false,
      link: '',
      rssDocument: false,
      dlrcDocument: false,
      europarlDocument: false,
      docHasAudio: false,
      otherTypeDocument: false,

      // For TXT view:
      bookmarks: [],
      bookmarksLoading: false,
      fileFetchDone: false,

      // For PDF View:
      pdfExists: false,
      pdfLoaded: false,
      showPDF: false,
      pdfBookmarks: [],
      pdfBookmarksLoading: false,
    };
  },

  computed: {
    // These have to be a functions instead of being added via mapGetters
    // because of the availabilty of the `this.dashboard` param.
    document() {
      return this.$store.getters[`${this.dashboard}Document`];
    },

    ...mapGetters({
      isIE11: 'isIE11',
      pdf: 'file',
      pdfMissing: 'fileMissing',
    }),

    documentId() {
      return this.$route.params.documentId;
    },

    highlightWord() {
      return this.$route.params.highlightWord;
    },

    kebabDashboard() {
      return this.dashboard
        .replace(/([A-Z])/g, '-$1') // Prepend capitals with a -
        .toLowerCase(); // Lowercase the capitals
    },
  },

  methods: {
    async setBookmarks() {
      if (this.docHasAudio) this.setAudioTextBookmarks();
      else this.setPlainTextBookmarks();
    },

    async setPlainTextBookmarks() {
      // Next tick to wait for document to render
      await this.$nextTick();

      // Function to wait for the element to be present,
      // since even with nextTick there are issues with document not being loaded before querying the selectors
      async function waitForElement(selector, timeout = 2000, interval = 100) {
        const endTime = Date.now() + timeout;
        while (Date.now() < endTime) {
          const element = document.querySelector(selector);
          if (element) return element;
          await sleep(interval); // eslint-disable-line no-await-in-loop
        }
        return null;
      }

      const documentText = await waitForElement('#documentText');

      const allBookmarksElements = documentText.querySelectorAll('.hlt1');
      this.bookmarks = this.reduceBookmarks(Array.from(allBookmarksElements));
    },

    async setAudioTextBookmarks() {
      const { document } = this;
      if (!document.textChunks?.length) return;

      this.bookmarksLoading = true;

      // Next tick to wait for document to render
      await this.$nextTick();

      const textChunksWithRegex = await Promise.all(document.textChunks.map(async (textChunk) => {
        textChunk.regexText = textChunk.text
          .trim() // The textChunks sometimes have starting spaces that are not present in the documentText
          .replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Escape regex special characters
          .replaceAll(/\b/g, '(<[^>]+>)?'); // Allow (highlight) html tags inserted before or after every word.
        return textChunk;
      }));

      let timer = new Date();
      let documentTextCopy = document.text;
      let processedChunkIds = [];

      const updateBookmarks = async () => {
        // wait till the last updated textChunk elems have been rerendered
        await this.$nextTick();
        // Find the highlight elems and add as bookmarks
        const newBookmarks = processedChunkIds.reduce((bookmarks, chunkId) => {
          bookmarks.push(...window.document.getElementById(chunkId).querySelectorAll('.hlt1'));
          return bookmarks;
        }, []);
        // Note that we only merge within one 'batch' of highlights
        this.bookmarks.push(...this.reduceBookmarks(Array.from(newBookmarks)));
        processedChunkIds = [];
      };

      // This loop finds the chunckText in the plainDocumentText and then copies over the highlights.
      for (let i = 0; i < textChunksWithRegex.length; i++) {
        // Call this logic once every 500 ms for performance reasons.
        if (new Date() - timer > 500) {
          timer = new Date();
          // Call sleep once every 500 ms so the rest of the page keeps loading.
          // Yes, sleeping 0 ms does do something: it allows other code to run first.
          await sleep(0); // eslint-disable-line no-await-in-loop

          await updateBookmarks(); // eslint-disable-line no-await-in-loop
        }

        const textChunk = textChunksWithRegex[i];
        const matches = documentTextCopy.match(new RegExp(textChunk.regexText));
        if (!matches) { // Should not happen
          console.warn('textChunkText was not found in full documentText, this should be impossible');
          return;
        }
        const [fullMatch, ...tagsMatches] = matches.filter((m) => m);
        if (tagsMatches.length) {
          textChunk.text = fullMatch; // Copy including highlight tags
          // Add tags to start and end of the chunk as a highlight may be split between two chunks:
          if (tagsMatches[0] === '</em>') textChunk.text = `<em class="hlt1">${textChunk.text}`;
          if (tagsMatches[tagsMatches.length - 1] === '<em class="hlt1">') {
            textChunk.text = `${textChunk.text}</em>`;
          }
        }
        // Remove this line from the documentText copy to speed up future searches. This also
        // prevents issues if we have two identical chunks, or one chunk entirely repeated in
        // a longer chunk. For the same reason we use `.*?` which matches as few chars as possible.
        documentTextCopy = documentTextCopy.replace(new RegExp(`^.*?${textChunk.regexText}`), '');

        processedChunkIds.push(textChunk._id);
      }
      await updateBookmarks();
      this.bookmarksLoading = false;
    },

    pdfRender() {
      this.pdfBookmarksLoading = true;
    },

    pdfPageReady(pageNum, pageElem, keywords) {
      this.$set(this.pdfBookmarks, pageNum, {
        pageNum,
        pageElem,
        keywords,
      });
    },

    pdfRendered() {
      this.pdfBookmarksLoading = false;
    },

    toggleShowPDF(value) {
      this.showPDF = value;
      if (!value) EventBus.$off('switchToTextView');
    },

    reduceBookmarks(bookmarkElements) {
      // Note: When we zoom the distance in pixels between
      // keywords changes which causes some bookmarks to merge
      // or split. So the set of bookmarks is not consistant.

      if (!bookmarkElements || bookmarkElements.length < 1) return [];
      const firstBookmark = bookmarkElements[0];
      let previousKeyword = firstBookmark.innerText.toLowerCase();
      let previousOffsetTop = this.getElemOffsetTop(firstBookmark);
      const reducedBookmarks = [firstBookmark];

      bookmarkElements.slice(1).forEach((bookmark) => {
        const keyword = bookmark.innerHTML.toLowerCase();
        const offsetTop = this.getElemOffsetTop(bookmark);

        if (
          previousKeyword !== keyword // Don't merge different keywords
          || (offsetTop - previousOffsetTop) > Config.minimalBookmarkDistance // Don't merge keywords too far apart
        ) {
          // Add as seperate bookmark
          reducedBookmarks.push(bookmark);
          previousKeyword = keyword;
          previousOffsetTop = offsetTop;
        }
      });

      return reducedBookmarks;
    },

    getElemOffsetTop(elem) {
      return elem.getBoundingClientRect().top // Distance from the element to the top of the current viewport.
        + document.documentElement.scrollTop; // Distance from document top to the top of the current viewport.
    },
  },

  watch: {
    pdfMissing(missing) {
      if (missing) this.showPDF = false;
    },

    document() {
      // Calculate the document offset only when the document data is available
      // we have to wait till the screen is drawn to get accurate offset
      const { type } = this.document;
      if (type) {
        if (type.startsWith('SB-') || type.startsWith('MD-')) {
          this.link = document.url;
          this.rssDocument = true;
        } else if (type.startsWith('CC') || type.startsWith('PC')) {
          this.dlrcDocument = true;
        } else if (type.startsWith('EUROPARL-') || type.startsWith('EUROCOM-')) {
          this.europarlDocument = true;
        } else this.otherTypeDocument = true;

        if (type.startsWith('DH-DEBATE')) {
          this.docHasAudio = true;
        }
      }

      setTimeout(() => {
        this.$store.dispatch('setDocumentId', null);
        this.$store.dispatch('setHighlightedWord', null);
        this.$store.dispatch('setIsEmailLink', null);
      }, 400);

      this.showPDF = !!(
        !this.isIE11
        && this.document
        && this.document.pdfInfo
        && this.document.pdfInfo.pdfSourceUrl
      );

      if (this.showPDF) {
        this.pdfBookmarks = {}; // clear pdfBookmarks
      } else {
        this.setBookmarks();
      }

      this.$nextTick(() => {
        if (this.document.pdfInfo && this.document.pdfInfo.pdfExists) {
          this.$store
            .dispatch('getFile', {
              docType: this.document.type,
              docId: this.documentId,
              mimeType: 'application/pdf',
            })
            .then(() => {
              this.pdfExists = true;
              this.pdfLoaded = true;
              this.fileFetchDone = true;
            }).catch(() => {
              this.fileFetchDone = true;
            });
        } else {
          this.fileFetchDone = true;
        }
      });
    },

    showPDF(showPDF) {
      if (showPDF) {
        this.pdfBookmarks = {}; // clear pdfBookmarks
      } else {
        this.setBookmarks();
      }
    },
  },
};
</script>
