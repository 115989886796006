<template>
  <feature-teaser-dialog title="EU Dashboard">
    <p>Dit document is onderdeel van het EU dashboard. Interesse? Neem gerust contact met ons op. We vertellen je er graag meer over.</p>
  </feature-teaser-dialog>
</template>

<script>
import FeatureTeaserDialog from '@/components/FeatureTeaserDialog.vue';

export default {
  components: {
    FeatureTeaserDialog,
  },
};
</script>
