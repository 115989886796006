<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  Zoeken in PDF
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          Om het PDF bestand snel aan je te kunnen tonen worden niet alle pagina’s in één keer geladen.
          Hierdoor werkt CTRL + F niet. Wil je deze functie alsnog gebruiken?
          Ga dan naar de tekstversie van het document of download het document om erin te zoeken.
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mr-2"
            @click="hideDialog()"
          >
            Annuleren
          </v-btn>

          <v-btn
            color="teal lighten-1"
            class="white--text"
            @click="activateFindInPage()"
          >
            Zoek in tekstversie
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { hideDialog } from '@/helpers/dialogHelper';
import EventBus from '@/event-bus';

export default {
  name: 'DocumentSwitchToTextView',

  props: {
    data: {
      type: Object,
      default() {
        return {

        };
      },
    },
  },

  data() {
    return {
      dialog: true,
    };
  },

  computed: {
  },

  methods: {
    hideDialog,

    cancelFindInPage() {
      this.hideDialog();
    },

    activateFindInPage() {
      EventBus.$emit('switchToTextView');
      this.hideDialog();
    },
  },
};
</script>
