<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-subheader class="mb-n2 overline">
            Bladwijzers
          </v-subheader>
          <v-card
            class="pa-2 mb-2"
            v-if="keywords"
          >
            <v-autocomplete
              v-model="selectedKeywords"
              :items="keywords"
              label="Filter bladwijzers"
              multiple
              dense
              hide-details
              clearable
              outlined
            >
              <template v-slot:prepend-inner>
                <v-icon
                  dense
                  style="height: 1.4em; margin-top: -2px;"
                  :color="selectedKeywords.length > 0 ? 'teal' : ''"
                >
                  mdi-filter-multiple
                </v-icon>
              </template>
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="toggleSelectAllKeywords"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedKeywords.length > 0 ? 'teal' : ''">
                      {{ autocompleteSelectAllIcon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Selecteer alles
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ index }">
                <div
                  v-if="index === 0"
                  class="grey--text text-caption"
                >
                  {{ selectedKeywords.length }} geselecteerd...
                </div>
              </template>
            </v-autocomplete>
          </v-card>
          <v-card>
            <v-list
              dense
              flat
            >
              <v-list-item-group color="teal">

                <!-- Scroll to the top -->
                <v-list-item
                  @click="scrollTo(0)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-bookmark</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Naar boven</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- scroll to bookmark -->
                <div
                  v-for="(page) in filteredBookmarksPerPage"
                  :key="page.pageNum"
                >
                  <v-subheader>
                    <v-btn
                      plain
                      @click="scrollToPage(page.pageElem)"
                      class="pa-0"
                    >
                      <v-icon
                        dense
                        class="mr-1"
                      >mdi-file-outline</v-icon>
                      Pagina {{ page.pageNum }}
                    </v-btn>
                    <v-chip
                      v-if="mostRelevantPages.includes(page.pageNum)"
                      class="ma-2"
                      small
                      pill
                      @click="scrollToPage(page.pageElem)"
                      :color="$vuetify.theme.dark ? '#786500 ' : 'yellow accent-1' "
                    >
                      <v-avatar
                        left
                        class="mr-0"
                      >
                        <v-icon>mdi-star</v-icon>
                      </v-avatar>
                      <span class="ml-n1">
                        Suggestie
                      </span>
                    </v-chip>
                  </v-subheader>
                  <v-list-item
                    class="bookmark"
                    v-for="(keyword, index) in Object.keys(page.keywords)"
                    :key="index"
                    @click="scrollToKeyword(page.pageElem, keyword)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-bookmark</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-tooltip
                        v-if="keyword.length > 35"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-title
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ keyword }} ({{ page.keywords[keyword] }})
                          </v-list-item-title>
                        </template>
                        <span>{{ keyword }} ({{ page.keywords[keyword] }})</span>
                      </v-tooltip>
                      <v-list-item-title
                        v-else
                      >
                        {{ keyword }} ({{ page.keywords[keyword] }})
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>

              </v-list-item-group>
            </v-list>
            <div
              v-if="pdfBookmarksLoading"
              class="spinner pa-2"
            >
              <v-progress-circular
                v-show="pdfBookmarksLoading"
                indeterminate
                :size="22"
                :width="3"
                class="mr-4"
              ></v-progress-circular>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {

  props: {
    pdfBookmarks: {
      type: Object,
      required: true,
    },
    pdfBookmarksLoading: {
      type: Boolean,
      required: true,
    },
    keywords: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedKeywords: [],
    };
  },

  computed: {
    filteredBookmarksPerPage() {
      return Object.entries(this.pdfBookmarks).map(
        ([, {
          pageNum, pageElem, keywords,
        }]) => {
          const filteredKeywords = Object.fromEntries(
            Object.entries(keywords).filter(([keyword]) => this.selectedKeywords.length === 0
                || this.selectedKeywords.includes(keyword)),
          );
          const uniqueMatches = Object.keys(filteredKeywords).length;
          if (uniqueMatches === 0) return null; // Don't include in bookmarks
          return {
            pageNum,
            pageElem,
            uniqueMatches,
            totalMatches: Object.values(filteredKeywords).reduce((a, b) => a + b, 0), // Sum
            keywords: filteredKeywords,
          };
        },
      ).filter((p) => p);
    },

    mostRelevantPages() {
      if (this.pdfBookmarksLoading || this.filteredBookmarksPerPage.length < 3) return [];

      // The most relevant page(s) is the one with the most unique matches on keywords.
      // Ties are broken with the most total matches.
      const mostRelevantPages = this.filteredBookmarksPerPage
        .reduce((mostRelevantPagesList, page) => {
          const { uniqueMatches, totalMatches } = page;
          if (mostRelevantPagesList.length === 0 && totalMatches > 0) return [page]; // Is new biggest by default
          const maxUniqueMatches = mostRelevantPagesList[0].uniqueMatches;
          if (uniqueMatches > maxUniqueMatches) return [page]; // Is new max, because it has more unique matches
          if (uniqueMatches < maxUniqueMatches) return mostRelevantPagesList; // Is smaller than max, so skip
          // Ties the current max unqiue keywords, totalMatches might break the tie.
          const maxTotalMatches = mostRelevantPagesList[0].totalMatches;
          if (totalMatches > maxTotalMatches) return [page]; // Is new max because more totalMatches
          if (totalMatches === maxTotalMatches) return [...mostRelevantPagesList, page]; // Add to list of pages with max
          return mostRelevantPagesList; // totalMatches is smaller than max, so skip
        }, []);

      // If all pages (with bookmarks) are the most relevant, then none are really relevant.
      if (mostRelevantPages.length === this.filteredBookmarksPerPage.length) return [];

      return mostRelevantPages.map(({ pageNum }) => pageNum);
    },

    selectedKeywordsAmount() {
      if (this.selectedKeywords.length === this.keywords.length) return 'all';
      if (this.selectedKeywords.length > 0) return 'some';
      return 'none';
    },

    autocompleteSelectAllIcon() {
      switch (this.selectedKeywordsAmount) {
        case 'all': return 'mdi-close-box';
        case 'some': return 'mdi-minus-box';
        default: return 'mdi-checkbox-blank-outline'; // None
      }
    },
  },

  methods: {
    async scrollToPage(pageElem) {
      pageElem.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
    async scrollToKeyword(pageElem, keyword) {
      let textSpans = pageElem.querySelectorAll('.textLayer span');
      let keywordLine;

      if (!textSpans || textSpans.length === 0) {
        // Scroll to the page to render the text with highlights
        await this.scrollToPage(pageElem);

        // Wait till page is rendered
        const startTime = new Date();
        const maxWaitTime = 4000; // 4s
        while (pageElem.dataset.rendered !== 'true' && (new Date() - startTime) < maxWaitTime) {
          // await required for sleep
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => setTimeout(resolve, 100));
        }

        // Try again
        textSpans = pageElem.querySelectorAll('.textLayer span');
      }

      // Find first line keyword is in:
      if (textSpans && textSpans.length > 0) {
        const ecapedKeyword = keyword && keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape
        keywordLine = Array.from(textSpans)
          .find((textSpan) => (new RegExp(`\\b(${ecapedKeyword})\\b`, 'i')).test(textSpan.innerText));

        // Scroll to that line. Fallback to top of page if render is too slow.
        if (keywordLine) {
          keywordLine.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    },

    scrollTo(yPosition) {
      if (this.isIE11) {
        window.scrollTo({ top: yPosition, left: 0, behavior: 'smooth' });
      } else {
        this.$vuetify.goTo(yPosition, {});
      }
    },

    // UNSELECT FOR A SELECT ALL OPTION
    toggleSelectAllKeywords() {
      if (['some', 'none'].includes(this.selectedKeywordsAmount)) this.selectedKeywords = this.keywords;
      else this.selectedKeywords = []; // all
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-autocomplete .mdi-close::before { // autocomplete clear button
  content: "\F01B4"; // Thrashcan 'mdi-delete'
  font-size: 0.8em;
}

// Make list denser
.v-list {
  .v-subheader {
    font-size: 1em;
  }

  .v-list-item {
    min-height: 2em;

    .v-list-item__icon {
      margin: 0 12px 0 8px;
    }

    .v-list-item__content {
      padding: 0;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
}
</style>
