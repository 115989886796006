<!--
  Component which displays a list of subjects
-->

<template>
  <v-expansion-panel class="">
    <v-expansion-panel-header>Documentgegevens</v-expansion-panel-header>
    <v-expansion-panel-content>
      <ul
        class="text-body-2 text--secondary"
      >
        <li
          v-if="institution"
        >
          Institutienaam: {{ institution }}
        </li>
        <li
          v-if="committees"
        >
          Commissie(s): {{ committees }}
        </li>
        <li
          v-if="reference"
        >
          Referentienummer(s): {{ reference }}
        </li>
        <li
          v-if="procedure"
        >
          Procedurenummer(s): {{ procedure }}
        </li>
        <li
          v-if="peNumber"
        >
          PE-nummer: {{ peNumber }}
        </li>
        <li
          v-if="cnReference"
        >
          CE-nummer: {{ cnReference }}
        </li>
        <li
          v-if="councilDocumentReference"
        >
          Nummer Raadsdocument: {{ councilDocumentReference }}
        </li>
        <li
          v-if="sessionDocumentNumber"
        >
          Sessienummer: {{ sessionDocumentNumber }}
        </li>
      </ul>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { euInstitutionCodeToName } from '@/helpers/groupingHelper';
import cleanReferenceNumber from '@/helpers/cleanReferenceNumberHelper';

import { mapGetters } from 'vuex';

export default {
  name: 'EuDocumentSpecs',

  components: {

  },

  data() {
    return {
      subjects: [
        'series',
        'set',
        'range',
        'sequence',
        'array',
        'line',
      ],
    };
  },

  props: {
    document: {
      type: Object,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      userFilters: 'userFilters',
    }),

    epCommitteesList() {
      const { euEpCommittees } = this.userFilters;
      return euEpCommittees;
    },

    institution() {
      return this.document.institution && euInstitutionCodeToName(this.document.institution);
    },

    committees() {
      const result = [];
      if (this.document.committees) {
        this.document.committees.forEach((item) => {
          this.epCommitteesList.forEach((obj) => {
            if (obj.code === item) {
              result.push(obj.nl);
            }
          });
        });
      }
      return result.toString();
    },

    reference() {
      const reference = [];
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (item.type === 'DOSSIER') {
            reference.push(cleanReferenceNumber(item.code));
          }
        });
      }
      return reference.toString().replace(',', ', ');
    },

    procedure() {
      const procedure = [];
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (item.type === 'PROCEDURE') {
            procedure.push(item.code);
          }
        });
      }
      return procedure.toString().replace(',', ', ');
    },

    peNumber() {
      const peNumber = [];
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (item.type === 'NUPE') {
            peNumber.push(item.code);
          }
        });
      }
      return peNumber.toString().replace(',', ', ');
    },

    cnReference() {
      const cnReference = [];
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (item.type === 'DOCC') {
            cnReference.push(item.code);
          }
        });
      }
      return cnReference.toString().replace(',', ', ');
    },

    councilDocumentReference() {
      const councilDocumentReference = [];
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (item.type === 'CSL') {
            councilDocumentReference.push(item.code);
          }
        });
      }
      return councilDocumentReference.toString().replace(',', ', ');
    },

    sessionDocumentNumber() {
      const sessionDocumentNumber = [];
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (item.type === 'NUSE') {
            sessionDocumentNumber.push(item.code);
          }
        });
      }
      return sessionDocumentNumber.toString().replace(',', ', ');
    },

  },

};
</script>
