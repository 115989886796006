<template>
  <v-layout justify-center="true">
    <v-btn @click="toggleShow" class="mt-1">
      {{ show ? 'Toon minder' : 'Toon meer' }}
    </v-btn>
  </v-layout>
</template>

<script>
export default {
  name: 'ShowMoreButton',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggleShow() {
      this.$emit('update:show', !this.show); // Emit an event to toggle visibility
    },
  },
};
</script>
