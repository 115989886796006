<template>
  <feature-teaser-dialog title="Eerste Kamer Module">
    <p>Dit document is onderdeel van de Eerste Kamer Module. Interesse? Neem gerust contact met ons op. We vertellen je er graag meer over.</p>
  </feature-teaser-dialog>
</template>

<script>
import FeatureTeaserDialog from '@/components/FeatureTeaserDialog.vue';

export default {
  components: {
    FeatureTeaserDialog,
  },
};
</script>
